var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout (timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();


/* jshint ignore:end */

// ACF Google Maps functions

/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $el (jQuery element)
*  @return  n/a
*/

function new_map( $el ) {

  // var
  var $markers = $el.find('.marker');


  // vars
  var args = {
    zoom    : 16,
    center    : new google.maps.LatLng(0, 0),
    mapTypeId : google.maps.MapTypeId.ROADMAP
  };


  // create map
  var map = new google.maps.Map( $el[0], args);


  // add a markers reference
  map.markers = [];


  // add markers
  $markers.each(function(){

    add_marker( jQuery(this), map );

  });


  // center map
  center_map( map );


  // return
  return map;

}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $marker (jQuery element)
*  @param map (Google Map object)
*  @return  n/a
*/

function add_marker( $marker, map ) {

  // var
  var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

  // create marker
  var marker = new google.maps.Marker({
    position  : latlng,
    map     : map
  });

  // add to array
  map.markers.push( marker );

  // if marker contains HTML, add it to an infoWindow
  if( $marker.html() )
  {
    // create info window
    var infowindow = new google.maps.InfoWindow({
      content   : $marker.html()
    });

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function() {

      infowindow.open( map, marker );

    });
  }

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param map (Google Map object)
*  @return  n/a
*/

function center_map( map ) {

  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  jQuery.each( map.markers, function( i, marker ){

    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

    bounds.extend( latlng );

  });

  // only 1 marker?
  if( map.markers.length == 1 )
  {
    // set center of map
      map.setCenter( bounds.getCenter() );
      map.setZoom( 16 );
  }
  else
  {
    // fit to bounds
    map.fitBounds( bounds );
  }

}

function forceLazyLoad() {
  // To force lazy loading of thumbnails, scroll 1px down and then up again
  jQuery(window).scrollTop(jQuery(window).scrollTop()+1);
  jQuery(window).scrollTop(jQuery(window).scrollTop()-1);
}

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
  
    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages

        // Show More Materials button
        $('#moreMaterials').on('shown.bs.collapse', function () {
          $('#moreMaterialsToggle .btn-more__label').html($('#moreMaterialsToggle').data('label-expanded'));
          forceLazyLoad();
        });

        $('#moreMaterials').on('hidden.bs.collapse', function () {
          $('#moreMaterialsToggle .btn-more__label').html($('#moreMaterialsToggle').data('label'));
        });

        // Show More Videos button
        $('#moreVideos').on('shown.bs.collapse', function () {
          $('#moreVideosToggle .btn-more__label').html($('#moreVideosToggle').data('label-expanded'));
        });

        $('#moreVideos').on('hidden.bs.collapse', function () {
          $('#moreVideosToggle .btn-more__label').html($('#moreVideosToggle').data('label'));
        });

        // Show Past Causes button
        $('#pastCauses').on('shown.bs.collapse', function () {
          $('#pastCausesToggle .btn-more__label').html($('#pastCausesToggle').data('label-expanded'));
        });

        $('#pastCauses').on('hidden.bs.collapse', function () {
          $('#pastCausesToggle .btn-more__label').html($('#pastCausesToggle').data('label'));
        });


        // Form fields empty on focus
        $('.newsletter-signup__email, #firstName, #lastName, #personalIdentificationCode, #email, #donationSumCustom').focus(function(){
          if (!$(this).data('first-focus-done')) {
            $(this).data('first-focus-done',true);
            $(this).val('');
          }
        });

        $('.newsletter-signup__email, #firstName, #lastName, #personalIdentificationCode, #email, #donationSumCustom').blur(function(){
          if ($(this).val()==='') {
            $(this).val($(this).attr('placeholder'));
            $(this).data('first-focus-done',false);
          }
        });

        // Uudiskirjaga liitumise vorm

        var mailChimpFormStrings = {
          "en-US": {
              "emailError":"A valid email address must be provided.",
              "inProcess":"Processing... Please wait.",
              "error":"We're sorry but the subscription failed. Please try again later.",
              "alreadyRegistered":"You are already registered. Thank you!",
              "thanks":"Thank you for registering. Please confirm the subscription in your inbox.",
          },
          "et": {
              "emailError":"Palun sisestage korrektne e-postiaadress.",
              "inProcess":"Liitumine töös... Palun oota.",
              "error":"Vabandame, Teid ei saa registreerida. Proovige hiljem uuesti.",
              "alreadyRegistered":"Olete juba registreeritud. Täname!",
              "thanks":"Täname! Peate liitumise kinnitama oma postkastis."
          },
        };

        var htmlLang = document.documentElement.lang;

        ajaxMailChimpForm($("#subscribe-form"), $("#subscribe-result"));
        // Turn the given MailChimp form into an ajax version of it.
        // If resultElement is given, the subscribe result is set as html to
        // that element.
        function ajaxMailChimpForm($form, $resultElement){
            // Define the language
            // Hijack the submission. We'll submit the form manually.
            $form.submit(function(e) {
                e.preventDefault();
                if (!isValidEmail($form)) {
                    var error =  mailChimpFormStrings[htmlLang].emailError;
                    $resultElement.html(error);
                    $resultElement.removeClass('alert-success d-none').addClass('alert-danger alert');
                } else {
                    var message = mailChimpFormStrings[htmlLang].inProcess;
                    $resultElement.removeClass('alert-danger d-none').addClass('alert-info alert');
                    $resultElement.html(message);
                    submitSubscribeForm($form, $resultElement);
                }
            });
        }
        // Validate the email address in the form
        function isValidEmail($form) {
            // If email is empty, show error message.
            // contains just one @
            var email = $form.find("input[type='email']").val();
            if (!email || !email.length) {
                return false;
            } else if (email.indexOf("@") == -1 && email.indexOf("@") == -1) {
                return false;
            }
            return true;
        }

        // Submit the MailChimp Signup form with an ajax/jsonp request.
        // Based on http://stackoverflow.com/a/15120409/215821
        function submitSubscribeForm($form, $resultElement) {
          $.ajax({
              type: "GET",
              url: $form.attr("action"),
              data: $form.serialize(),
              cache: false,
              dataType: "jsonp",
              jsonp: "c", // trigger MailChimp to return a JSONP response
              contentType: "application/json; charset=utf-8",
              error: function(error){
                  // According to jquery docs, this is never called for cross-domain JSONP requests
              },
              success: function(data){
                  if (data.result != "success") {
                      var message = data.msg || mailChimpFormStrings[htmlLang].error;
                      $resultElement.removeClass('alert-success').addClass('alert-danger alert');
                      if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
                          $resultElement.css("color", "black");
                          message = mailChimpFormStrings[htmlLang].alreadyRegistered;
                      }
                      $resultElement.html(message);
                  } else {
                      $resultElement.removeClass('alert-danger').addClass('alert-success alert');
                      $resultElement.html(mailChimpFormStrings[htmlLang].thanks);
                  }
              }
          });
        }

      },
      finalize: function() {
        $('[data-toggle="tooltip"]').tooltip();

        // Smooth scroll to anchor links
        // https://stackoverflow.com/a/7717572
        $(document).on('click', 'a[href^="#"]', function (event) {
          if ($.attr(this,'role')!=='tab' &&
            !$(this).hasClass("xt_woofc-trigger") &&
            !$(this).hasClass("xt_woofc-delete-item") &&
            !$(this).hasClass("remove") &&
            !$(this).hasClass("xt_woofc-undo") &&
            !$(this).hasClass("dropdown-toggle") &&
            !$(this).hasClass("scroll-to-donate-box")
            ) {
            event.preventDefault();

            $('html, body').animate({
              scrollTop: $($.attr(this, 'href')).offset().top
            }, 500);
          }
        });

        // "All" tab
        $("#tabAll").on("click", function(e) {
          e.preventDefault();
          $(this).addClass("active").parent("li").siblings().find("a").removeClass("active");
          $(".tab-pane").removeClass("fade").addClass("active").addClass("show");
        });
        $(".members-sector-filter .nav-link").not("#tabAll").on("click", function() {
          $(".tab-pane").not(this.hash).removeClass("active").removeClass("show");
        });

        // JavaScript to be fired on all pages, after page specific JS is fired
        // Equal Heights
        // $('.equal-height-image').equalHeights();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Event page
    'single_events': {
      init: function() {
        // JavaScript to be fired on the single event
        var map = null;
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $('#collapseMap').on('shown.bs.collapse', function () {
           $('.acf-map').each(function(){
            // create map
            map = new_map( $(this) );
          });
        });

      }
    },
    // Materials archive page
    'post_type_archive_materials': {
      init: function() {
      },
      finalize: function() {
        
        // JavaScript to be fired on the materials archive page, after the init JS

        /**
         * @description determine if an array contains one or more items from another array.
         * @param {array} haystack the array to search.
         * @param {array} arr the array providing items to check for in the haystack.
         * @return {boolean} true|false if haystack contains at least one item from arr.
         */
        var findOne = function (haystack, arr) {
          return arr.some(function (v) {
              return haystack.indexOf(v) >= 0;
          });
        };
        
        // Function for searching by keyword on the Materials page

        function searchMaterials() {
          // Declare variables
          var input, filter, li, a, i, txtValue;
          input = document.getElementById('fuzzySearch');
          filter = input.value.toUpperCase();
          li = document.getElementsByClassName('material');

          // Loop through all list items, and hide those who don't match the search query
          for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("h2")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
            } else {
              li[i].style.display = "none";
            }
          }

          if (filter!=="") {
            $( "#moreMaterials" ).collapse('show');
            $("#moreMaterialsToggle").hide(); 
          }

          else {
            $("#moreMaterialsToggle").show();
          }
        }

        $( "#fuzzySearch" ).keyup(function() {
          searchMaterials();
          forceLazyLoad();
        });

        // Function for the topics tabs filters on the Materials page

        function filterTopics(input) {
          // Declare variables
          var topic, li, topics, topicsArray, material, i, t, txtValue;
          topic = input.data('id').toString();
          topicChildren = input.data('children');
          console.log(topicChildren);
          li = document.getElementsByClassName('material');

          if (topicChildren) {
            topicsArray = topicChildren.toString().split(', ');
            topicsArray.push(topic);
            console.log(topicsArray);
          }

          // Loop through all list items, and hide those who don't match the search query
          for (i = 0; i < li.length; i++) {

            if (topic==="allTopics") {
              li[i].classList.remove("filter--not-in-category");
              li[i].classList.remove("filter--in-category");
            }

            else {
              material = li[i];
              materialTopics = $(material).data('topics');
              materialTopicsArray = materialTopics.toString().split(', ');

              if (!topicChildren) {

                if (materialTopicsArray.includes(topic)) {
                  li[i].classList.add("filter--in-category");
                  li[i].classList.remove("filter--not-in-category");
                }

                else {
                  li[i].classList.remove("filter--in-category");
                  li[i].classList.add("filter--not-in-category");
                }
              }

              else {
                //console.log('has children');
                //var found = findOne(materialTopicsArray,topicsArray);
                //const found = topicsArray.some(r=> materialTopicsArray.includes(r));
               // console.log(found);

                if (findOne(materialTopicsArray,topicsArray)){
                  li[i].classList.add("filter--in-category");
                  li[i].classList.remove("filter--not-in-category");
                }

                else {
                  li[i].classList.remove("filter--in-category");
                  li[i].classList.add("filter--not-in-category");
                }
              }
            }
          }

          if (topic!=="allTopics") {
            $("#moreMaterials").collapse('show');
            $("#moreMaterialsToggle").hide();
          }
          else {
            $("#moreMaterialsToggle").show();
          }
        }

        $( "#materialsTopicsFilter li a" ).click(function(e) {
          e.preventDefault();
          filterTopics($(this));
          forceLazyLoad();
        });

      }
    },
    // Donate 2023 page
    'page_template_template_donate_2023': {
      init: function() {
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // LightGallery
        $('#lightgallery').lightGallery({
          selector: '.lightgallery__item',
          currentPagerPosition : 'center',
          exThumbImage: 'data-exthumbimage'
        });

        // Smooth scroll inner links
        $('.scroll-to-donate-box').on('click',function (e) {
          e.preventDefault();
          var $target = $('#form');

          $target.removeClass('shake');

          $('html, body').stop().animate({
              'scrollTop': $target.offset().top-70
          }, 500, 'swing', function () {
              // Callback
              $target.removeClass('shake');
              $target.addClass('shake');
          });
      });

      // *****************
      // Donation Form
      // *****************

      // Enables tooltips
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });

      function donateSaveData(href) {
        var donationType = jQuery('input[name="donationType"]:checked').val();

        // Set bank if recurring
        if (donationType!=='donationOnce') {
          donationBank = jQuery('input[name="recurringBank"]:checked').val();
        }
        window.open(href);
      }

      function alterDonationSum () {
        donationSumValue = $('input[name="donationSum"]:checked').val();
        alterButtonText(donationType);
      }

      function alterButtonText (donationType) {
        $('.donationFormSubmitSumText').html(donationSumValue);
      }

      function alterExplanatoryTab (activeTab) {
        $('#donationSumTabs').find(activeTab).addClass('active').siblings().removeClass('active');
      }

      function alterDonationSubmitButtonHref () {
        var donationLink = "";
        console.log("Lingi muutmine: ");

        var lang = jQuery('html').attr('lang');
        var lang_alt1 = "";
        var lang_alt2 = "";
        if (lang === 'et') {
          lang_alt1 = 'EE';
          lang_alt2 = 'EST';
        }
        else {
          lang_alt1 = 'EN';
          lang_alt2 = 'ENG';
        }

        console.log("Annetuse tüüp " + donationTypeValue);
        
        if (donationTypeValue==="donationRecurring") {
          $(".donationFormSubmitMonthlyText").removeClass('d-none');

          if (donationBankValue==="lhv") {
            donationLink = "https://www.lhv.ee/portfolio/payment_standing_add.cfm?i_receiver_name=Sihtasutus%20Eesti%20Inim%C3%B5iguste%20Keskus&i_receiver_account_no=" + donationBankAccountNumber + "&i_payment_desc=Annetus&i_payment_clirefno=123&i_amount=" + donationSumValue + "&l3=" + lang;
          }
          else if (donationBankValue==="seb") {
            donationLink = "https://e.seb.ee/ip/ipank?act=ADDSOSMARTPAYM&lang=EST&field1=benname&value1=Sihtasutus+Eesti+Inim%C3%B5iguste+keskus&field3=benacc&value3=" + donationBankAccountNumber + "&field10=desc&value10=Annetus&field11=refid&value11=&field5=amount&value5=" + donationSumValue + "&sofield1=frequency&sovalue1=3&paymtype=REMSEBEE&field6=currency&value6=EUR&sofield2=startdt&sofield3=enddt&sovalue4=CIF&sofield4=paymtype";
          }
          else if (donationBankValue==="luminor") {
            donationLink = "https://luminor.ee/?m=login-modal";
          }
          else if (donationBankValue==="swedbank") {
            donationLink = "https://www.swedbank.ee/private/d2d/payments2/standing_order/new?standingOrder.beneficiaryName=Sihtasutus+Eesti+Inim%C3%B5iguste+keskus&standingOrder.beneficiaryAccountNumber=" + donationBankAccountNumber + "&standingOrder.currency=EUR&standingOrder.details=Annetus&frequency=K&standingOrder.amount=" + donationSumValue;
          }
          else if (donationBankValue==="coop") {
            donationLink = "https://i.cooppank.ee/login";
          }
          donationFormSubmitButtonRecurring.attr("href",donationLink);

          // Alter modal info
          $('#paymentDetailsDonationSum').html(donationSumValue);
          $('#paymentDetailsDonationBankAccountNumber').html(donationBankAccountNumber);
          $('#paymentDetailsDonationBank').html(donationBankValue);
          $('#paymentDetailsSubmitButtonDonationBank').html(donationBankValue);
        }
        else {
          $(".donationFormSubmitMonthlyText").addClass('d-none');
          donationLink = "https://payment.maksekeskus.ee/pay/1/link.html?shopId=625e4635-899a-4c35-bf8e-dce23868860f&paymentId="+donationPersonalIdentificationCodeValue+"&merchant_data="+donationFirstNameValue+"+"+donationLastNameValue+"+"+donationPersonalIdentificationCodeValue+"+"+donationEmailValue+"&amount="+donationSumValue+"&locale=et";
          donationFormSubmitButtonOnce.attr("href",donationLink);
        }

      }

      var donationType = $('input[name="donationType"]');
      var donationRadioSum = $('input[name="donationSum"]');
      var donationCustomSum = $('input[name="donationSumCustom"]');
      var donationCustomSumRadio = $('#sumCustom');
      var donationFirstName = $('#firstName');
      var donationLastName = $('#lastName');
      var donationPersonalIdentificationCode = $('#personalIdentificationCode');
      var donationEmail = $('#email');
      var donationBank = $('input[name="recurringBank"]');

      var donationTypeValue = "donationOnce";
      var donationCustomSumValue = "";
      var donationFirstNameValue = "";
      var donationLastNameValue = "";
      var donationPersonalIdentificationCodeValue = "";
      var donationEmailValue = "";
      var donationBankValue = "swedbank";
      var donationBankAccountNumber = donationBank.data('account-number');

      var donationSumValue = "";

      var donationFormSubmitButtonOnce = $('#donationFormSubmitOnce');
      var donationFormSubmitButtonRecurring = $('#donationFormSubmitRecurring');

      // Get initial donation sum
      alterDonationSum();
      alterButtonText(donationType);
      alterExplanatoryTab();

      // Set up field listeners
      donationType.change(function(){
        console.log(donationTypeValue = $(this).val());
        $(this).parent().parent().addClass('active').siblings().removeClass('active');
        if(donationTypeValue==="donationRecurring") {
          $("#donationRecurring").parent().parent().tooltip({
            'title': 'Aitäh :)!',
          });
          $("#donationFormContinueSecond").show();
          $("#donationFormSubmitOnce").hide();
          // Go to third screen if donation type changed to recurring
          if ($('#donation-form-page--second').css('display') === 'block') {
            $('#donation-form-page--second').hide();
            $('#donation-form-page--third').show();
          }
        }
        else {
          $("#donationFormContinueSecond").hide();
          $("#donationFormSubmitOnce").show();
          $("#donationRecurring").parent().parent().tooltip('show');

          // Go back from third screen if donation type changed to one time donation
          if ($('#donation-form-page--third').css('display') === 'block') {
            $('#donation-form-page--third').hide();
            $('#donation-form-page--second').show();
          }
        }
        alterDonationSubmitButtonHref();
      });

      donationRadioSum.change(function(e){
        $(this).parent().parent().addClass('active').siblings().removeClass('active');
        console.log(donationRadioSumValue = $(this).val());
        alterDonationSum();
        alterButtonText(donationType);
        alterExplanatoryTab($(this).data('target'));
        alterDonationSubmitButtonHref();
      });


      // Focus the custom sum input field when user clicks on radio button next to it
      donationCustomSumRadio.on('click', function(){
        donationCustomSum.focus();
        alterExplanatoryTab($(this).data('target'));
      });

      donationCustomSum.on('focus input', function(){
        console.log("Custom summa väli aktiivne");
        $(this).siblings().prop('checked', true);
        $('#sumCustom').val($(this).val());
        alterDonationSum();
        alterDonationSubmitButtonHref();
        alterExplanatoryTab($(this).data('target'));
      });

      donationFirstName.change(function(e){
        console.log(donationFirstNameValue = $(this).val());
        alterDonationSubmitButtonHref();
      });

      donationLastName.change(function(e){
        console.log(donationLastNameValue = $(this).val());
        alterDonationSubmitButtonHref();
      });

      donationPersonalIdentificationCode.keyup(function(e){
        console.log(donationPersonalIdentificationCodeValue = $(this).val());
        alterDonationSubmitButtonHref();
      });

      donationEmail.change(function(e){
        console.log(donationEmailValue = $(this).val());
        alterDonationSubmitButtonHref();
      });

      donationBank.change(function(e){
        $(this).parent().parent().addClass('active').siblings().removeClass('active');
        console.log(donationBankValue = $(this).val());
        donationBankAccountNumber = $(this).data('account-number');
        alterDonationSubmitButtonHref();
      });

      $('#donationFormContinue').click(function(e) {
        e.preventDefault();
        if (donationCustomSumRadio.prop('checked') && donationCustomSum.val() === "") {
          alert($(this).data('error-no-sum'));
          return;
        }
        alterDonationSubmitButtonHref();
        if (donationTypeValue==="donationOnce") {
          $('#donation-form-page--first').hide();
          $('#donation-form-page--second').show();
        }
        else {
           $('#donation-form-page--first').hide();
           $('#donation-form-page--third').show();
        }
      });

      $('#donationFormContinueSecond').click(function(e) {
        e.preventDefault();
        alterDonationSubmitButtonHref();
        $('#donation-form-page--second').hide();
        $('#donation-form-page--third').show();
      });

      $('#donationFormBackSecond').click(function(e) {
        e.preventDefault();
        $('#donation-form-page--second').hide();
        $('#donation-form-page--first').show();
      });

      $('#donationFormBackThird').click(function(e) {
        e.preventDefault();
        if (donationTypeValue==="donationOnce") {
          $('#donation-form-page--third').hide();
          $('#donation-form-page--second').show();
        }
        if (donationTypeValue==="donationRecurring") {
          $('#donation-form-page--third').hide();
          $('#donation-form-page--first').show();
        }
      });

      donationFormSubmitButtonOnce.click(function(e) { // use jQuery no conflict methods replace $ with "jQuery"
        var href = this.href;  // get href from link
        // stop post action
        e.preventDefault();
        donateSaveData(href);
      });

      donationFormSubmitButtonRecurring.click(function(e) { // use jQuery no conflict methods replace $ with "jQuery"
        var href = this.href;  // get href from link
        // stop post action
        e.preventDefault();
        donateSaveData(href);
      });

      }
    },
    // Donation page, note the change from page-template-template-donate to page_template_template_donate.
    'page_template_template_donate': {
      init: function() {
        // JavaScript to be fired on the donation page

        // Smooth scroll inner links
        $('#helpLink').on('click',function (e) {
            e.preventDefault();
            var $target = $('#faq');

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

        // *****************
        // Donation Form
        // *****************

        // Enables tooltips
        $(function () {
          $('[data-toggle="tooltip"]').tooltip();
        });

        function donateSaveData(href) {
          var donationSumValue = '';
          var donationCustomSumValue = jQuery('input[name="donationSumCustom"]').val();
          if (jQuery('input[name="donationSum"]:checked').length > 0) {
            donationSumValue = jQuery('input[name="donationSum"]:checked').val();
          }
          else {
            donationSumValue = donationCustomSumValue;
          }
          var donationType = jQuery('input[name="donationType"]:checked').val();

          // Set bank if recurring
          var donationBank = '';
          if (donationType!=='donationOnce') {
            donationBank = jQuery('input[name="recurringBank"]:checked').val();
          }

          window.open(href);
        }

        function alterDonationSum () {
          if ($('input[name="donationSum"]:checked').length > 0) {
            donationSumValue = $('input[name="donationSum"]:checked').val();
          }
          else {
            donationSumValue = donationCustomSumValue;
          }
          alterButtonText();
        }

        function alterButtonText () {
          $('.donationFormSubmitSumText').html(donationSumValue);
        }

        function alterDonationSubmitButtonHref () {
          var donationLink = "";
          console.log("Lingi muutmine: ");

          var lang = jQuery('html').attr('lang');
          var lang_alt1 = "";
          var lang_alt2 = "";
          if (lang === 'et') {
            lang_alt1 = 'EE';
            lang_alt2 = 'EST';
          }
          else {
            lang_alt1 = 'EN';
            lang_alt2 = 'ENG';
          }
          if (donationTypeValue==="donationRecurring") {
            console.log("Annetuse tüüp " + donationTypeValue);
            $("#donationFormSubmitMonthlyText").show();
            if (donationBankValue==="lhv") {
              donationLink = "https://www.lhv.ee/portfolio/payment_standing_add.cfm?i_receiver_name=Sihtasutus%20Eesti%20Inim%C3%B5iguste%20Keskus&i_receiver_account_no=" + donationBankAccountNumber + "&i_payment_desc=Annetus&i_payment_clirefno=123&i_amount=" + donationSumValue + "&l3=" + lang;
            }
            else if (donationBankValue==="seb") {
              donationLink = "https://e.seb.ee/ip/ipank?act=ADDSOSMARTPAYM&lang=EST&field1=benname&value1=Sihtasutus+Eesti+Inim%C3%B5iguste+keskus&field3=benacc&value3=" + donationBankAccountNumber + "&field10=desc&value10=Annetus&field11=refid&value11=&field5=amount&value5=" + donationSumValue + "&sofield1=frequency&sovalue1=3&paymtype=REMSEBEE&field6=currency&value6=EUR&sofield2=startdt&sofield3=enddt&sovalue4=CIF&sofield4=paymtype";
            }
            else if (donationBankValue==="luminor") {
              donationLink = "https://luminor.ee/?m=login-modal";
            }
            else if (donationBankValue==="swedbank") {
              donationLink = "https://www.swedbank.ee/private/d2d/payments2/standing_order/new?standingOrder.beneficiaryName=Sihtasutus+Eesti+Inim%C3%B5iguste+keskus&standingOrder.beneficiaryAccountNumber=" + donationBankAccountNumber + "&standingOrder.currency=EUR&standingOrder.details=Annetus&frequency=K&standingOrder.amount=" + donationSumValue;
            }
            else if (donationBankValue==="coop") {
              donationLink = "https://i.cooppank.ee/login";
            }
            donationFormSubmitButtonRecurring.attr("href",donationLink);

            // Alter modal info
            $('#paymentDetailsDonationSum').html(donationSumValue);
            $('#paymentDetailsDonationBankAccountNumber').html(donationBankAccountNumber);
            $('#paymentDetailsDonationBank').html(donationBankValue);
            $('#paymentDetailsSubmitButtonDonationBank').html(donationBankValue);
          }
          else {
            $("#donationFormSubmitMonthlyText").hide();
            donationLink = "https://payment.maksekeskus.ee/pay/1/link.html?shopId=625e4635-899a-4c35-bf8e-dce23868860f&paymentId="+donationPersonalIdentificationCodeValue+"&merchant_data="+donationFirstNameValue+"+"+donationLastNameValue+"+"+donationPersonalIdentificationCodeValue+"+"+donationEmailValue+"&amount="+donationSumValue+"&locale=et";
            donationFormSubmitButtonOnce.attr("href",donationLink);
          }

        }


        var donationType = $('input[name="donationType"]');
        var donationRadioSum = $('input[name="donationSum"]');
        var donationCustomSum = $('input[name="donationSumCustom"]');
        var donationCustomSumRadio = $('input[name="donationSumCustomRadio"]');
        var donationFirstName = $('#firstName');
        var donationLastName = $('#lastName');
        var donationPersonalIdentificationCode = $('#personalIdentificationCode');
        var donationEmail = $('#email');
        var donationBank = $('input[name="recurringBank"]');

        var donationTypeValue = "donationOnce";
        var donationRadioSumValue = "";
        var donationCustomSumValue = "";
        var donationFirstNameValue = "";
        var donationLastNameValue = "";
        var donationPersonalIdentificationCodeValue = "";
        var donationEmailValue = "";
        var donationBankValue = "swedbank";
        var donationBankAccountNumber = donationBank.data('account-number');

        var donationLastRadioSum = "";
        var donationSumValue = "";

        var donationFormSubmitButtonOnce = $('#donationFormSubmitOnce');
        var donationFormSubmitButtonRecurring = $('#donationFormSubmitRecurring');

        // Get initial donation sum
        alterDonationSum();
        alterButtonText();

        // Set up field listeners
        donationType.change(function(){
          console.log(donationTypeValue = $(this).val());
          $(this).parent().parent().addClass('active').siblings().removeClass('active');
          if(donationTypeValue==="donationRecurring") {
            $("#donationRecurring").parent().parent().tooltip({
              'title': 'Aitäh :)!',
            });
            $("#donationFormContinueSecond").show();
            $("#donationFormSubmitOnce").hide();
            // Go to third screen if donation type changed to recurring
            if ($('#donation-form-page--second').css('display') === 'block') {
              $('#donation-form-page--second').hide();
              $('#donation-form-page--third').show();
            }
          }
          else {
            $("#donationFormContinueSecond").hide();
            $("#donationFormSubmitOnce").show();
            $("#donationRecurring").parent().parent().tooltip('show');

            // Go back from third screen if donation type changed to one time donation
            if ($('#donation-form-page--third').css('display') === 'block') {
              $('#donation-form-page--third').hide();
              $('#donation-form-page--second').show();
            }
          }
          alterDonationSubmitButtonHref();
        });

        donationRadioSum.change(function(e){
          console.log("Summa muutmine");
          $(this).parent().parent().addClass('active').siblings().removeClass('active');
          console.log(donationRadioSumValue = $(this).val());
          alterDonationSum();
          alterDonationSubmitButtonHref();
          alterButtonText($(this).val());
          donationCustomSumRadio.prop('checked', false);
          
        });

        donationCustomSum.on('focus input', function(){
          donationCustomSumValue = $(this).val();
          $('.donation-form__sum').children().removeClass('active');
          if (donationRadioSum.val() !== "") {
            // Remembers the last chosen Radio Sum value for possible recovery
            donationRadioSum.prop('checked', false);
            donationCustomSumRadio.prop('checked', true);
          }
          alterDonationSum();
          alterDonationSubmitButtonHref();
        });

        donationFirstName.change(function(e){
          console.log(donationFirstNameValue = $(this).val());
          alterDonationSubmitButtonHref();
        });

        donationLastName.change(function(e){
          console.log(donationLastNameValue = $(this).val());
          alterDonationSubmitButtonHref();
        });

        donationPersonalIdentificationCode.keyup(function(e){
          console.log(donationPersonalIdentificationCodeValue = $(this).val());
          alterDonationSubmitButtonHref();
        });

        donationEmail.change(function(e){
          console.log(donationEmailValue = $(this).val());
          alterDonationSubmitButtonHref();
        });

        donationBank.change(function(e){
          $(this).parent().parent().addClass('active').siblings().removeClass('active');
          console.log(donationBankValue = $(this).val());
          donationBankAccountNumber = $(this).data('account-number');
          alterDonationSubmitButtonHref();
        });

        $('#donationFormContinue').click(function(e) {
          e.preventDefault();
          if (donationCustomSumRadio.prop('checked') && donationCustomSum.val() === "") {
            alert($(this).data('error-no-sum'));
            return;
          }
          alterDonationSubmitButtonHref();
          if (donationTypeValue==="donationOnce") {
            $('#donation-form-page--first').hide();
            $('#donation-form-page--second').show();
          }
          else {
             $('#donation-form-page--first').hide();
             $('#donation-form-page--third').show();
          }
        });

        $('#donationFormContinueSecond').click(function(e) {
          e.preventDefault();
          alterDonationSubmitButtonHref();
          $('#donation-form-page--second').hide();
          $('#donation-form-page--third').show();
        });

        $('#donationFormBackSecond').click(function(e) {
          e.preventDefault();
          $('#donation-form-page--second').hide();
          $('#donation-form-page--first').show();
        });

        $('#donationFormBackThird').click(function(e) {
          e.preventDefault();
          if (donationTypeValue==="donationOnce") {
            $('#donation-form-page--third').hide();
            $('#donation-form-page--second').show();
          }
          if (donationTypeValue==="donationRecurring") {
            $('#donation-form-page--third').hide();
            $('#donation-form-page--first').show();
          }
        });

        donationFormSubmitButtonOnce.click(function(e) { // use jQuery no conflict methods replace $ with "jQuery"
          var href = this.href;  // get href from link
          // stop post action
          e.preventDefault();
          donateSaveData(href);
        });

        donationFormSubmitButtonRecurring.click(function(e) { // use jQuery no conflict methods replace $ with "jQuery"
          var href = this.href;  // get href from link
          // stop post action
          e.preventDefault();
          donateSaveData(href);
        });
      },
    },
    'materials_template_template_hierarchic_material_sub': {
      init: function() {
      },
      finalize: function() {
        $("#breadcrumbs-bar").sticky({topSpacing:0,zIndex:999});
      },
    },
    'page_template_template_topic_page': {
      init: function() {
      },
      finalize: function() {

        $('body').scrollspy({ target: '#pageNav' });
        $("#pageNav").sticky({topSpacing:0,zIndex:999});

        // Add Events to Page Navigation

        var pageNavCandidates = [
          "#events",
          "#uritused",
          "#newsletter",
          "#uudiskiri",
          "#news",
          "#seotud-uudised",
          "#materials",
          "#materjalid",
          "#activities",
          "#projektid",
          "#people",
          "#inimesed",
          "#trash"
        ];

        var pageNavList = $("#pageNavList");

        $.each( pageNavCandidates, function( index, e ){
          // console.log($(element));
          if ($(e).length) {
            pageNavList.append('<li class="nav-item"><a class="nav-link" href="' + e +  '">' + $(e).data("title") +  '</a></li>');
          }
        });
      },
    },
    // Donation page, note the change from page-template-template-donate to page_template_template_donate.
    'page_template_template_subtopic_page': {
      init: function() {
      },
      finalize: function() {
        $('body').scrollspy({ target: '#pageNav' });
        $("#pageNav").sticky({topSpacing:0,zIndex:999});

        // Expand and collapse diversity orgs extra information
        $('.table-collapse-controller').each(function(){
          var target = $(this).data('target');
          $(this).click(function(){
            $(this).children('span').toggleClass('icon-plus icon-minus');
            $(target).toggle();
          });
        });

        // Expand and collapse cards
        $('.card-collapse-controller').each(function(){
          var target = $(this).data('target');
          $(this).click(function(){
            $(this).children('.card-expand').toggleClass('icon-keyboard_arrow_down_gray icon-keyboard_arrow_up_gray');
            $(target).toggle();
          });
        });

        // LightGallery
        $('#lightgallery').lightGallery({
            selector: '.lightgallery__item',
            currentPagerPosition : 'center',
            exThumbImage: 'data-exthumbimage'
        });

        // JavaScript to be fired on the diversity organizations page, after the init JS
        function searchMembers() {
          // Declare variables
          var input, filter, ul, li, a, i, txtValue;
          input = document.getElementById('fuzzySearch');
          filter = input.value.toUpperCase();
          li = document.getElementsByClassName('member');

          // Loop through all list items, and hide those who don't match the search query
          for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("h4")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
            } else {
              li[i].style.display = "none";
            }
          }
        }

        $( "#fuzzySearch" ).keyup(function() {
          searchMembers();
        });
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
